import { FINANCIALS_DATE_SELECTION_SHEET } from 'common/constants/financials';
import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import getRowConfig from './getRowConfig';
import { parser, reverseParser } from '../utilities';

const createFinancialsDateConfig = ({ financialStatement, isDisabled }) => {
  const contentColumns = [financialStatement];

  return new SpreadsheetConfig({
    columns: contentColumns,
    name: FINANCIALS_DATE_SELECTION_SHEET,
    rowConfig: getRowConfig({ financialStatement, isDisabled }),
    tableData: { financial_statement: financialStatement, isDisabled },
    hasColTitle: false,
    parser,
    reverseParser,
    initialObj: financialStatement,
    allowConfirmAndDeleteColumn: false,
    allowDeleteColumn: false,
    handleDeleteColumn: false,
    showTitlesColumn: true,
    displayRowIndicator: false,
    displayColumnIndicator: false,
  });
};

export default createFinancialsDateConfig;
