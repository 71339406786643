import React, { FC, useContext } from 'react';
import { SelectValueViewer } from 'components';
import ValuationContext from '../../ValuationContext';

const ApproachScenarioSelectValueViewer: FC = props => {
  const { allocationMethodsOptions } = useContext(ValuationContext);
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: SelectValueViewer is not typed
    <SelectValueViewer {...props} options={allocationMethodsOptions} />
  );
};

export default ApproachScenarioSelectValueViewer;
