import { useCallback, useEffect, useMemo, useState } from 'react';
import { AxiosError } from 'axios';
import { isEmpty, isUndefined } from 'lodash';
import { ApiService, MeasurementDateRollover, MeasurementDateRolloverReturn } from 'api';
import { gridShortDate } from 'utillities/datesFormats';
import { ROLLOVER_TYPE_KEYS, RolloverRequestInfo, RolloverStatusList, STATUS, SubItem } from './rollover.types';
import { ERROR_409, ERROR_504 } from '../../../common/config/api';

const separateIntoMultipleRequests = (postData: MeasurementDateRollover) => {
  const fundRequests: MeasurementDateRollover[] = [];
  const allocationRequests: MeasurementDateRollover[] = [];
  const primaryCTOnlyRequests: MeasurementDateRollover[] = [];

  if (postData.fund_ids && postData.fund_ids.length > 0) {
    postData.fund_ids.forEach(fundId => {
      fundRequests.push({
        new_measurement_date: postData.new_measurement_date,
        firm_id: postData.firm_id,
        fund_ids: [fundId],
      });
    });
  }
  if (postData.primary_ct_only_company_ids && postData.primary_ct_only_company_ids.length > 0) {
    postData.primary_ct_only_company_ids.forEach(companyId => {
      primaryCTOnlyRequests.push({
        new_measurement_date: postData.new_measurement_date,
        firm_id: postData.firm_id,
        primary_ct_only_company_ids: [companyId],
        reset_financials: postData.reset_financials,
        exclude_documents: postData.exclude_documents,
      });
    });
  }
  if (postData.allocation_rollovers && postData.allocation_rollovers.length > 0) {
    postData.allocation_rollovers.forEach(allocationRollover => {
      allocationRequests.push({
        new_measurement_date: postData.new_measurement_date,
        firm_id: postData.firm_id,
        allocation_rollovers: [allocationRollover],
        reset_financials: postData.reset_financials,
        exclude_documents: postData.exclude_documents,
      });
    });
  }
  return { fundRequests, allocationRequests, primaryCTOnlyRequests };
};

const ERROR_DURING_ROLLOVER = 'An error occurred during the rollover process.';
const getErrorMessage = (error: AxiosError, request: MeasurementDateRollover) => {
  if (error.response?.status?.toString() === ERROR_409) {
    if (!isEmpty(request.fund_ids)) {
      return `A measurement date for ${gridShortDate(request.new_measurement_date)} already exists for this fund.`;
    }
    return `A measurement date for ${gridShortDate(request.new_measurement_date)} already exists for this company.`;
  }
  return (error.response?.data as any)?.detail || ERROR_DURING_ROLLOVER;
};

const getResponseStatus = (error: AxiosError): STATUS => {
  if (error.response?.status?.toString() === ERROR_409) {
    return STATUS.CONFLICT;
  }
  return STATUS.ERROR;
};

export const useRolloverCompanies = (subItems: SubItem[]) => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const [combinedResponse, setCombinedResponse] = useState<MeasurementDateRolloverReturn>();

  const [statusList, setStatusList] = useState<RolloverStatusList>({
    funds: [],
    allocations: [],
    capTablesOnly: [],
  });

  const allocationCompanyIdMap = useMemo<{ [index: string]: string }>(
    () =>
      subItems.reduce((acc, subItem) => {
        if (!subItem.allocationId || !subItem.id) {
          return acc;
        }
        return {
          ...acc,
          [subItem.allocationId.toString()]: subItem.id.toString(),
        };
      }, {}),
    [subItems]
  );

  const updateStatusList = useCallback(
    (
      type: ROLLOVER_TYPE_KEYS,
      request: RolloverRequestInfo,
      status: STATUS,
      response?: MeasurementDateRolloverReturn,
      errorMsg?: string
    ) => {
      setStatusList(prevStatusList => ({
        ...prevStatusList,
        [type]: prevStatusList[type].map(prevRequest => {
          if (prevRequest.id === request.id) {
            return {
              ...prevRequest,
              status,
              errorMsg,
              response,
            };
          }
          return prevRequest;
        }),
      }));
    },
    []
  );

  const pollMeasurementDateExists = useCallback(
    (request: RolloverRequestInfo, type: ROLLOVER_TYPE_KEYS, id: string, date: string) => {
      let attempts = 0;
      const maxAttempts = 6; // 6 attempts * 10 seconds = 60 seconds
      const pollInterval = 10000; // 10 seconds

      const poll = async () => {
        attempts += 1;
        const data = await ApiService.apiCompanyMeasurementDatesExistsRead(id, date);
        if (data.exists && data.company_measurement_date) {
          updateStatusList(type, request, STATUS.SUCCESS, { company_mds: [data.company_measurement_date] });
        } else if (attempts < maxAttempts) {
          setTimeout(() => {
            poll();
          }, pollInterval);
        } else {
          updateStatusList(type, request, STATUS.ERROR, undefined, ERROR_DURING_ROLLOVER);
        }
      };
      setTimeout(() => {
        poll();
      }, pollInterval);
    },
    [updateStatusList]
  );

  const rolloverCompanies = useCallback(async (postData: MeasurementDateRollover) => {
    const { fundRequests, allocationRequests, primaryCTOnlyRequests } = separateIntoMultipleRequests(postData);
    const tmpStatusList = {
      funds: fundRequests.map(request => ({
        request,
        status: STATUS.LOADING,
        id: request.fund_ids ? request.fund_ids[0] : null,
      })),
      allocations: allocationRequests.map(request => ({
        request,
        status: STATUS.LOADING,
        id: request.allocation_rollovers ? request.allocation_rollovers[0].allocation_id : null,
      })),
      capTablesOnly: primaryCTOnlyRequests.map(request => ({
        request,
        status: STATUS.LOADING,
        id: request.primary_ct_only_company_ids ? request.primary_ct_only_company_ids[0] : null,
      })),
    };
    setStatusList(tmpStatusList);
  }, []);

  const handleRequests = useCallback(
    (requests: RolloverRequestInfo[], type: ROLLOVER_TYPE_KEYS) => {
      requests.forEach(request => {
        request.request
          && ApiService.apiMeasurementDatesCompanyRolloverCreate(request.request)
            .then(response => {
              updateStatusList(type, request, STATUS.SUCCESS, response);
            })
            .catch(error => {
              if (type !== ROLLOVER_TYPE_KEYS.FUNDS && error.response?.status?.toString() === ERROR_504) {
                if (type === ROLLOVER_TYPE_KEYS.ALLOCATIONS && request.id && request.id in allocationCompanyIdMap) {
                  pollMeasurementDateExists(
                    request,
                    type,
                    allocationCompanyIdMap[request.id],
                    request.request?.new_measurement_date as string
                  );
                } else if (type === ROLLOVER_TYPE_KEYS.CAP_TABLES_ONLY && request.id) {
                  pollMeasurementDateExists(
                    request,
                    type,
                    request.id.toString(),
                    request.request?.new_measurement_date as string
                  );
                }
              } else {
                const errorMsg = getErrorMessage(error, request.request as MeasurementDateRollover);
                const status = getResponseStatus(error);
                updateStatusList(type, request, status, undefined, errorMsg);
              }
            });
      });
    },
    [pollMeasurementDateExists, updateStatusList, allocationCompanyIdMap]
  );

  useEffect(() => {
    if (
      isUndefined(isLoading)
      && (statusList.funds.length || statusList.allocations.length || statusList.capTablesOnly.length)
    ) {
      handleRequests(statusList.funds, ROLLOVER_TYPE_KEYS.FUNDS);
      handleRequests(statusList.allocations, ROLLOVER_TYPE_KEYS.ALLOCATIONS);
      handleRequests(statusList.capTablesOnly, ROLLOVER_TYPE_KEYS.CAP_TABLES_ONLY);

      setIsLoading(true);
    }
  }, [statusList, isLoading, handleRequests]);

  useEffect(() => {
    // if every thing in the statusList is success, then we can stop loading
    const isEveryThingSuccess
      = statusList.funds.every(fund => fund.status !== STATUS.LOADING)
      && statusList.allocations.every(allocation => allocation.status !== STATUS.LOADING)
      && statusList.capTablesOnly.every(capTable => capTable.status !== STATUS.LOADING)
      && (statusList.funds.length > 0 || statusList.allocations.length > 0 || statusList.capTablesOnly.length > 0);
    if (isEveryThingSuccess) {
      setIsLoading(false);
      // we now want to look through all of the status list and combine the responses into one
      const combinedResponse: MeasurementDateRolloverReturn = {
        company_mds: [],
        fund_mds: [],
      };
      statusList.funds.forEach(fund => {
        fund.response && combinedResponse.fund_mds?.push(...(fund.response.fund_mds ?? []));
      });
      statusList.allocations.forEach(allocation => {
        allocation.response && combinedResponse.company_mds?.push(...(allocation.response.company_mds ?? []));
      });
      statusList.capTablesOnly.forEach(capTable => {
        capTable.response && combinedResponse.company_mds?.push(...(capTable.response.company_mds ?? []));
      });
      setCombinedResponse(combinedResponse);
    }
  }, [statusList]);

  return { isLoading, rolloverCompanies, statusList, response: combinedResponse };
};
