import { gridShortDate } from 'utillities';

const parser = ({ columns, rowConfig }) => {
  const column = columns[0];
  let cells = {};
  const columnLegend = 'A';
  rowConfig.forEach((row, rowIdx) => {
    const rowNumber = rowIdx + 1;
    const key = `${columnLegend}${rowNumber}`;
    const value = gridShortDate(column[row.alias] || row.defaultValue);

    cells = {
      ...cells,
      [key]: {
        ...row,
        key,
        columnLegend,
        value,
        className: row.className || '',
      },
    };
  });
  return cells;
};

export default parser;
