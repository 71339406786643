import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Box, Grid, LinearProgress } from '@material-ui/core';
import { difference, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { BACKSOLVE, OPM, SCENARIO_METHODS_BACKSOLVE, WATERFALL } from 'common/constants/allocations';
import { CAP_TABLE_CURRENCY_PAGE } from 'common/constants/currencyPageTypes';
import { VALUATIONS_PAGE_KEY, VALUATIONS_PAGE_VALUE } from 'common/constants/notes';
import {
  MARKET_ADJUSTMENT_REQUIREMENT,
  VALUATIONS_MARKET_ADJUSTMENT_ADD,
  VALUATIONS_MARKET_ADJUSTMENT_REMOVE,
} from 'common/constants/valuations';
import { useFormat } from 'common/hooks';
import { Widgets } from 'components';
import GridButtonWithTooltip from 'components/ActionButtons/GridButtonWithTooltip';
import { LayoutContext } from 'context';
import BacksolveContext from 'context/BacksolveContext';
import { getValidSecurities } from 'pages/CapTable/cap-table/utilities';
import BacksolveSummaryTable from 'pages/Valuations/approaches/backsolveApproach/BacksolveSummaryTable/BacksolveSummaryTable';
import BacksolveTable from 'pages/Valuations/approaches/backsolveApproach/BacksolveTable/BacksolveTable';
import {
  ALLOCATION_METHOD_ALIAS,
  CAP_TABLE_ALIAS,
  VALUATION_APPROACH_GPC_ALIAS,
} from 'pages/Valuations/approaches/backsolveApproach/constants';
import MarketAdjustmentTable from 'pages/Valuations/approaches/backsolveApproach/MarketAdjustmentTable/MarketAdjustmentTable';
import OPMInputTable from 'pages/Valuations/approaches/backsolveApproach/OPMInputTable/OPMInputTable';
import SecurityTable from 'pages/Valuations/approaches/backsolveApproach/SecurityTable/SecurityTable';
import { TARGET_VALUE_ALIAS } from 'pages/Valuations/approaches/backsolveApproach/SecurityTable/util/constants';
import getRowConfig from 'pages/Valuations/approaches/backsolveApproach/SecurityTable/util/getRowConfig';
import useCalculateEquityValue from 'pages/Valuations/approaches/backsolveApproach/useCalculateEquityValue';
import { PUBLIC_COMPANIES } from 'pages/Valuations/util/constants';
import ValuationContext from 'pages/ValuationsAllocation/ValuationContext';
import CapatableService from 'services/captable';
import { useNotes } from 'services/hooks/notes';
import AddActionButton from './AddActionButton/AddActionButton';
import getBacksolveTableRowConfig from './BacksolveTable/util/rowconfig';
import calculateWeightedPerShareValues from './calculateWeightedPerShareValue';

export const getOpmData = spreadsheets => {
  const opmCells = spreadsheets.opmInputSheet.cells;
  // Maturity, volatility, risk-free rate, and backsolve date
  return [opmCells.B2, opmCells.B5, opmCells.B6, opmCells.B1];
};

export const getEditableSecurityValues = securities => securities.map(({ security, shares }) => ({ security, shares }));

const Backsolve = ({ spreadsheets, onChange: onWorkbookChange, workbook, approaches, capTableList, approach }) => {
  const { resetConfiguration, measurementDate, setNotesInApproach, updateApproachesScenarioMethods }
    = useContext(ValuationContext);
  const { viewOnlyUser } = useContext(LayoutContext);

  const { notes, setNotes, notesHasChanged, onAddNote, onUpdateNotes, onDeleteNote } = useNotes();
  const approachPanelId = approach.panelId;
  const { isDisabled } = spreadsheets.backsolveSheet.tableData;

  const [format, formatDispatch] = useFormat({ page: CAP_TABLE_CURRENCY_PAGE });

  const [calculateEquityValue, isLoading] = useCalculateEquityValue();

  const hasGpcApproach = useMemo(
    () => approaches.filter(({ approach_type }) => approach_type === PUBLIC_COMPANIES).length,
    [approaches]
  );

  const getSelectedCapTable = useCallback(
    capTableId => {
      if (Array.isArray(capTableList)) {
        return capTableList?.find(({ id }) => id.toString() === capTableId?.toString());
      }
    },
    [capTableList]
  );

  const getAppliedMethodologies = useCallback(
    () => spreadsheets.backsolveSheet.tableData.approach.valuations_approach_backsolve.applied_methodologies,
    [spreadsheets]
  );

  const checkIfIsOpm = useCallback(() => {
    const methods = getAppliedMethodologies();

    return methods.some(x => Number(x.allocation_method) === Number(OPM));
  }, [getAppliedMethodologies]);

  // flag to hide and display the opm inputs table
  const [isOpm, setOpm] = useState(checkIfIsOpm());

  const getApproachByPanelId = useCallback(
    id => {
      if (!id) {
        return undefined;
      }
      return approaches
        .filter(({ approach_type }) => approach_type === PUBLIC_COMPANIES)
        .find(
          approachCandidate =>
            approachCandidate.panelId === id
            || (approachCandidate.valuations_approach_gpc.id
              && approachCandidate.valuations_approach_gpc.id?.toString() === id.toString())
        );
    },
    [approaches]
  );

  const [gpcApproach, setGpcApproach] = useState(
    getApproachByPanelId(approach.valuations_approach_backsolve.gpc_approach)
  );
  const [selectedCapTable, setSelectedCapTable] = useState(
    getSelectedCapTable(spreadsheets.backsolveSheet.cells.capTableSelection?.value)
  );

  useEffect(() => {
    if (capTableList?.length > 0) {
      setSelectedCapTable(getSelectedCapTable(spreadsheets.backsolveSheet.cells.capTableSelection?.value));
    }
  }, [capTableList, getSelectedCapTable, spreadsheets]);

  const [prevOpmData, setPrevOpmData] = useState();
  const [prevEditableSecurityValues, setPrevEditableSecurityValues] = useState();
  const [prevTargetValue, setPrevTargetValue] = useState();
  const [securityList, setSecurityList] = useState([]);

  const captableChange = useCallback(
    capTable => {
      const securityCells = spreadsheets.securitySheet.cells;
      Object.values(securityCells).forEach(cell => {
        if (cell.alias === 'security') {
          onWorkbookChange(cell, '');
        }
      });
      const updatedApproach = { ...approach };
      const firstAppliedMethodology = updatedApproach.valuations_approach_backsolve.applied_methodologies[0];
      firstAppliedMethodology.cap_table = capTable.id;

      const updatedRowConfig = getBacksolveTableRowConfig({ captableList: capTableList, approach: updatedApproach });
      spreadsheets.backsolveSheet.reset({
        rowConfig: updatedRowConfig,
      });
      resetConfiguration();
    },
    [onWorkbookChange, spreadsheets, resetConfiguration, capTableList, approach]
  );

  useEffect(() => {
    const svc = new CapatableService();
    if (selectedCapTable) {
      svc.getCaptableById(selectedCapTable.id).then(response => {
        setSecurityList(response.securities);
      });
    }
  }, [selectedCapTable]);

  const getSecurityRows = useCallback(
    () => approach.valuations_approach_backsolve.securities_basket.basket,
    [approach]
  );

  const getAllocationMethods = useCallback(
    () => approach.valuations_approach_backsolve.applied_methodologies,
    [approach]
  );

  const securityRows = useMemo(() => {
    if (approach) {
      return getSecurityRows();
    }
    return [];
  }, [getSecurityRows, approach]);

  const allocationMethods = useMemo(() => {
    if (approach) {
      return getAllocationMethods();
    }
    return [];
  }, [getAllocationMethods, approach]);

  const getScenarioMethod = useCallback(
    () => spreadsheets.backsolveSheet.cells.allocationMethod?.value,
    [spreadsheets]
  );

  const allocation = useMemo(() => approach.allocationId, [approach.allocationId]);

  const areAppliedMethodologiesValid = useCallback(() => {
    const methodologies
      = spreadsheets.backsolveSheet.tableData.approach?.valuations_approach_backsolve?.applied_methodologies;

    const duplicatedAllocationMethods = methodologies.filter(
      m => methodologies.filter(x => x.allocation_method === m.allocation_method)?.length > 1
    );

    return duplicatedAllocationMethods.length === 0;
  }, [spreadsheets]);

  const arePresentShareValuesReady = useMemo(() => {
    const methodologies
      = spreadsheets.backsolveSheet.tableData.approach?.valuations_approach_backsolve?.applied_methodologies;
    if (methodologies) return methodologies.every(m => m.present_share_values.length > 0);
    return false;
  }, [spreadsheets]);

  const getTargetValue = useCallback(() => {
    const rowIndex = spreadsheets.securitySheet.tableData.securitiesBasket.basket.length + 2;
    const cellKey = `D${rowIndex}`;
    return spreadsheets.securitySheet.cells[cellKey]?.value;
  }, [spreadsheets]);

  useEffect(() => {
    if (selectedCapTable && areAppliedMethodologiesValid() && !isEmpty(securityList) && !arePresentShareValuesReady) {
      const data = {
        captable: selectedCapTable,
        scenarioType: BACKSOLVE,
        allocation,
        appliedMethodologies: getAppliedMethodologies(),
        opmData: getOpmData(spreadsheets),
        securityRows,
        scenarioMethod: getScenarioMethod(),
        targetValue: getTargetValue(),
        prevOpmData,
        editableLedgerCells: getEditableSecurityValues(securityRows),
        prevEditableLedgerCells: prevEditableSecurityValues,
        prevTargetValue,
        securityList,
        spreadsheets,
        onCellsChanged: onWorkbookChange,
      };

      calculateEquityValue(data);
    }
    // disabling because we only want this to load when the page does, or when the security list changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [securityList, arePresentShareValuesReady]);

  const recalculateEquityValue = useCallback(
    (captable, basket = securityRows, targetValue = null) => {
      if (captable && areAppliedMethodologiesValid()) {
        const data = {
          captable: captable.id,
          scenarioType: BACKSOLVE,
          allocation,
          appliedMethodologies: getAppliedMethodologies(),
          opmData: getOpmData(spreadsheets),
          securityRows: basket,
          scenarioMethod: getScenarioMethod(),
          targetValue: targetValue ?? getTargetValue(),
          prevOpmData,
          editableLedgerCells: getEditableSecurityValues(basket),
          prevEditableLedgerCells: prevEditableSecurityValues,
          prevTargetValue,
          securityList,
          spreadsheets,
          onCellsChanged: onWorkbookChange,
        };
        return calculateEquityValue(data) || {};
      }
    },
    [
      areAppliedMethodologiesValid,
      allocation,
      getScenarioMethod,
      getAppliedMethodologies,
      onWorkbookChange,
      spreadsheets,
      getTargetValue,
      calculateEquityValue,
      securityList,
      securityRows,
      prevTargetValue,
      prevOpmData,
      prevEditableSecurityValues,
    ]
  );

  const onChange = useCallback(
    (cell, value, skipEquityRecalculation = false) => {
      // changes from market adjustment table should not have the equity value recalculated
      if (cell.alias === VALUATION_APPROACH_GPC_ALIAS && value) {
        // should probably be a panel id
        setGpcApproach(getApproachByPanelId(value));
      }
      if (cell.alias === TARGET_VALUE_ALIAS) {
        setPrevTargetValue(cell.value);
        // calculate pershare values, total values, and equity values
      }
      let capTable = selectedCapTable;
      if (cell.alias === CAP_TABLE_ALIAS) {
        capTable = getSelectedCapTable(value);
        if (capTable && capTable.id !== selectedCapTable?.id) {
          setSelectedCapTable(capTable);
          captableChange(capTable);
        }
      }
      onWorkbookChange(cell, value);
      if (cell.alias === ALLOCATION_METHOD_ALIAS) {
        setOpm(checkIfIsOpm());
        updateApproachesScenarioMethods();
      }
      // backsolve
      if (!skipEquityRecalculation) {
        recalculateEquityValue(capTable);
      }
    },
    [
      selectedCapTable,
      onWorkbookChange,
      checkIfIsOpm,
      getApproachByPanelId,
      getSelectedCapTable,
      captableChange,
      recalculateEquityValue,
      updateApproachesScenarioMethods,
    ]
  );

  const [hasMarketAdjustment, setHasMarketAdjustment] = useState(
    approach.valuations_approach_backsolve.adjust_for_market
  );

  useEffect(() => {
    if (!hasMarketAdjustment) {
      // eslint-disable-next-line no-param-reassign
      approach.valuations_approach_backsolve.public_companies_status = [];
    }
  }, [approach, hasMarketAdjustment]);

  const [marketAdjustment, setMarketAdjustment] = useState(approach.valuations_approach_backsolve.market_adjustment);

  const changeMarketAdjustment = useCallback(() => {
    const { valuations_approach_backsolve } = approach;
    // eslint-disable-next-line max-len
    valuations_approach_backsolve.adjust_for_market = !valuations_approach_backsolve.adjust_for_market;
    if (!valuations_approach_backsolve.adjust_for_market) {
      // eslint-disable-next-line no-param-reassign
      valuations_approach_backsolve.gpc_approach = null;
      setMarketAdjustment(approach.valuations_approach_backsolve.market_adjustment);
    }
    setHasMarketAdjustment(valuations_approach_backsolve.adjust_for_market);
  }, [approach]);

  // this is used to set and reset the enterprise value in the market adjustment table
  // as that cell is used to update the panel and the summary. When we remove a market adjustment
  // this erases the market adjustment cell and thus makes the enterprise value in both tables
  // equal.
  useEffect(() => {
    let value = 0;
    if (hasMarketAdjustment) {
      value = Number(marketAdjustment);
    }
    // only fire if spreadsheets.marketAdjustmentSheet.cells.MarketAdjustment is different from the value
    const cellValue = Number(spreadsheets.marketAdjustmentSheet.cells.MarketAdjustment.value);

    if (cellValue !== value) {
      onChange(spreadsheets.marketAdjustmentSheet.cells.MarketAdjustment, value);
    }
  }, [hasMarketAdjustment, marketAdjustment, onChange, spreadsheets.marketAdjustmentSheet.cells.MarketAdjustment]);

  const getUpdatedBacksolveApproach = currentAllocationMethods =>
    currentAllocationMethods.map(item => ({
      ...item,
      // reset weight to 100% when there's only 1 method left
      weight: currentAllocationMethods.length === 1 ? '1' : item.weight,
      isDeleteableColumn: currentAllocationMethods.length > 1,
    }));

  const addAllocationMethod = useCallback(async () => {
    const defaultCapTable = capTableList?.length ? capTableList.find(ct => ct.is_primary)?.id : undefined;

    const { backsolveSheet } = spreadsheets;
    const { applied_methodologies: currentAllocationMethods }
      = backsolveSheet.tableData.approach.valuations_approach_backsolve;
    currentAllocationMethods.push({
      allocation_method: WATERFALL.toString(),
      cap_table: selectedCapTable?.id || defaultCapTable,
      weight: '',
      maturity: null,
      volatility: null,
      present_share_values: [],
    });

    const updatedAllocationMethods = await getUpdatedBacksolveApproach(currentAllocationMethods);
    backsolveSheet.tableData.approach.valuations_approach_backsolve.applied_methodologies = updatedAllocationMethods;

    backsolveSheet.reset({
      tableData: backsolveSheet.tableData,
      columns: [[], ...updatedAllocationMethods],
    });
    resetConfiguration();
    updateApproachesScenarioMethods();
  }, [resetConfiguration, spreadsheets, capTableList, selectedCapTable, updateApproachesScenarioMethods]);

  const addSecurity = useCallback(() => {
    const { securitySheet } = spreadsheets;
    const { basket } = securitySheet.tableData.securitiesBasket;
    basket.push({
      security: '',
      shares: '',
      value: '',
      per_share: '',
    });

    securitySheet.reset({
      tableData: securitySheet.tableData,
      rowConfig: getRowConfig(basket, spreadsheets.backsolveSheet.name),
    });
    resetConfiguration();
  }, [resetConfiguration, spreadsheets]);

  const deleteSecurity = useCallback(
    async securityIndex => {
      const { securitySheet } = spreadsheets;
      const { basket, target_value: tv } = securitySheet.tableData.securitiesBasket;
      basket.splice(securityIndex - 1, 1);

      // if the basket is empty, reset the sheet and add a new row to have a clean state
      if (isEmpty(basket)) {
        securitySheet.reset({
          tableData: securitySheet.tableData,
          rowConfig: getRowConfig(basket, spreadsheets.backsolveSheet.name),
        });
        addSecurity();
        resetConfiguration();
        return;
      }
      // if not, recalculate equity value based on new basket but same target value
      // get distribution for new equity value
      const { newDistribution } = await recalculateEquityValue(selectedCapTable, basket, tv);
      const methods = getAppliedMethodologies();
      const newPresentValues = newDistribution?.map(dist => dist.presentValues);
      const totalPresentShareValues = calculateWeightedPerShareValues(newPresentValues, methods);

      const basketToSet = basket.map(row => {
        const perShare = totalPresentShareValues?.find(({ securityId }) => Number(securityId) === Number(row.security));
        return { ...row, per_share: perShare?.value };
      });
      securitySheet.reset({
        tableData: securitySheet.tableData,
        rowConfig: getRowConfig(basketToSet),
      });

      resetConfiguration();
    },
    [spreadsheets, recalculateEquityValue, selectedCapTable, getAppliedMethodologies, resetConfiguration, addSecurity]
  );

  const deleteAllocationMethod = useCallback(
    async allocationMethodIndex => {
      const { backsolveSheet } = spreadsheets;

      const { applied_methodologies: currentAllocationMethods }
        = backsolveSheet.tableData.approach.valuations_approach_backsolve;
      // if it was stored I want to store the deleted id in "deleted_methodologies"
      if (currentAllocationMethods.length > 1) {
        const deletedMethod = currentAllocationMethods.splice(allocationMethodIndex - 1, 1);
        const deletedMethodId = deletedMethod[0].id;

        if (deletedMethodId) {
          backsolveSheet.tableData.approach.valuations_approach_backsolve.deleted_methodologies.push(deletedMethodId);
        }

        const updatedAllocationMethods = await getUpdatedBacksolveApproach(currentAllocationMethods);
        backsolveSheet.tableData.approach.valuations_approach_backsolve.applied_methodologies
          = updatedAllocationMethods;

        backsolveSheet.reset({
          tableData: backsolveSheet.tableData,
          columns: [[], ...updatedAllocationMethods],
        });
        resetConfiguration();
        setOpm(checkIfIsOpm());
        // Recalculate Equity Value when there's only 1 method left
        if (updatedAllocationMethods.length === 1) {
          recalculateEquityValue(selectedCapTable);
        }
      }
    },
    [checkIfIsOpm, resetConfiguration, spreadsheets, recalculateEquityValue, selectedCapTable]
  );

  // When a change is made to a ledger we need to save the values returned from that save.
  const onMarketAdjustmentLedgerChange = useCallback(
    ledgerData => {
      const { marketAdjustmentSheet } = spreadsheets;
      const changes = [];
      changes.push([marketAdjustmentSheet.cells.AsOfDate, ledgerData.AsOfDate]);
      changes.push([marketAdjustmentSheet.cells.Metric, ledgerData.Metric]);
      changes.push([marketAdjustmentSheet.cells.MarketAdjustment, ledgerData.AppliedAdjustment]);
      changes.forEach(([cell, expr]) => {
        onChange(cell, expr, true);
      });
      // eslint-disable-next-line no-param-reassign
      approach.valuations_approach_backsolve.public_companies_status = ledgerData.compData.map(comp => ({
        id: comp.id,
        public_company: comp.public_company || null,
        ltm_revenue: comp.ltm_revenue,
        ltm_ebitda: comp.ltm_ebitda,
        ntm_revenue: comp.ntm_revenue,
        ntm_ebitda: comp.ntm_ebitda,
        market_cap: comp.market_cap,
        enterprise_value: comp.enterprise_value,
        enabled: comp.enabled,
        reference_for_backsolve: comp.reference_for_backsolve,
        ticker_symbol: comp.identifier,
        cap_iq_id: comp.cap_iq_id,
      }));
      const { savedPublicCompStatusIds } = marketAdjustmentSheet.tableData;
      const toPersistPublicCompStatusIds = Array.from(new Set(ledgerData.compData.map(comp => comp.id)));
      const toDeletePublicCompStatusIds = Array.from(
        difference(savedPublicCompStatusIds, toPersistPublicCompStatusIds)
      );
      // eslint-disable-next-line no-param-reassign
      approach.valuations_approach_backsolve.deleted_public_company_status_ids = Array.from(
        new Set([
          ...(approach.valuations_approach_backsolve.deleted_public_company_status_ids ?? []),
          ...toDeletePublicCompStatusIds,
        ])
      );

      // also we store some of the other data from the ledger
      const otherUpdates = {
        percentile_selection_a: ledgerData.percentile_selection_a_selection,
        percentile_selection_b: ledgerData.percentile_selection_b_selection,
        adjustment_selected: ledgerData.SelectedAdjustment,
        specified_adjustment: ledgerData.SpecifiedAdjustment,
      };
      // eslint-disable-next-line no-param-reassign
      approach.valuations_approach_backsolve = {
        ...approach.valuations_approach_backsolve,
        ...otherUpdates,
      };
      setMarketAdjustment(ledgerData.AppliedAdjustment);
    },
    [approach, onChange, spreadsheets]
  );

  const backsolveContextData = useMemo(
    () => ({
      gpcApproach,
      securityList,
      spreadsheets,
      approach,
      onMarketAdjustmentLedgerChange,
      measurementDate,
      format,
      formatDispatch,
    }),
    [
      // eslint-disable-next-line max-len
      gpcApproach,
      securityList,
      spreadsheets,
      approach,
      onMarketAdjustmentLedgerChange,
      measurementDate,
      format,
      formatDispatch,
    ]
  );

  useEffect(() => {
    if (!isEmpty(notes)) {
      setNotesInApproach(prevState => {
        const tmpNotes = prevState.filter(note => note.panelId !== approachPanelId);
        return [...tmpNotes, { notes, panelId: approachPanelId, notesHasChanged }];
      });
    }
  }, [notes, notesHasChanged, approachPanelId, setNotesInApproach]);

  return (
    <BacksolveContext.Provider value={backsolveContextData}>
      <Box width="100%" display="flex" flexDirection="column">
        <Grid container direction="column" spacing={3}>
          <Grid item container direction="column">
            <Grid item>{isLoading && <LinearProgress />}</Grid>
            <Grid item>
              <BacksolveTable
                spreadsheets={spreadsheets}
                onChange={onChange}
                workbook={workbook}
                deleteColFn={deleteAllocationMethod}
              />
            </Grid>
          </Grid>
          <Grid item>
            <AddActionButton
              onClickHandler={addAllocationMethod}
              text="Add Allocation Method"
              disabled={allocationMethods.length >= SCENARIO_METHODS_BACKSOLVE.length || isDisabled}
            />
          </Grid>
        </Grid>
        <Grid container direction="column" spacing={3} style={{ marginTop: '1.125rem' }}>
          <Grid item>
            <SecurityTable
              spreadsheets={spreadsheets}
              onChange={onChange}
              workbook={workbook}
              securityList={securityList}
              setPrevEditableSecurityValues={setPrevEditableSecurityValues}
              securities={securityRows}
              deleteRowFn={deleteSecurity}
            />
          </Grid>
          <Grid item>
            <AddActionButton
              onClickHandler={addSecurity}
              disabled={securityRows.length >= getValidSecurities(selectedCapTable).length || isDisabled}
              text="Add Row"
            />
          </Grid>
        </Grid>
        <Grid container direction="column" spacing={3} style={{ marginTop: '1.125rem' }}>
          <Grid item>
            {isOpm && (
              <OPMInputTable
                componentId="approach-opm-input-table"
                spreadsheets={spreadsheets}
                onChange={onChange}
                setPrevOpmData={setPrevOpmData}
                fullWidth={false}
                workbook={workbook}
                measurementDate={measurementDate}
              />
            )}
          </Grid>
          <Grid item>
            <BacksolveSummaryTable
              spreadsheets={spreadsheets}
              onChange={onChange}
              format={format}
              formatDispatch={formatDispatch}
              workbook={workbook}
            />
          </Grid>
        </Grid>
        <Grid container direction="column" spacing={3} style={{ marginTop: '0.5rem', marginBottom: '1.125rem' }}>
          {!viewOnlyUser && (
            <GridButtonWithTooltip
              tooltipText={MARKET_ADJUSTMENT_REQUIREMENT}
              buttonText={hasMarketAdjustment ? VALUATIONS_MARKET_ADJUSTMENT_REMOVE : VALUATIONS_MARKET_ADJUSTMENT_ADD}
              onClick={changeMarketAdjustment}
              disabled={!hasGpcApproach}
              className="btn-table-aligned"
            />
          )}
          <Grid item>
            {hasMarketAdjustment && (
              <MarketAdjustmentTable
                spreadsheets={spreadsheets}
                onChange={onChange}
                format={format}
                formatDispatch={formatDispatch}
                workbook={workbook}
              />
            )}
          </Grid>
        </Grid>
        <Widgets
          notesProps={{
            pageType: VALUATIONS_PAGE_VALUE,
            pageTypeKey: VALUATIONS_PAGE_KEY,
            pageTypeId: approach.id,
            notes,
            isApproach: true,
            setNotes,
            onAddNote,
            onUpdateNotes,
            onDeleteNote,
            isDisabled,
          }}
        />
      </Box>
    </BacksolveContext.Provider>
  );
};

export default Backsolve;

Backsolve.propTypes = {
  spreadsheets: PropTypes.shape({}),
  approach: PropTypes.shape({
    panelId: PropTypes.number,
    valuations_approach_backsolve: PropTypes.shape({
      gpc_approach: PropTypes.number,
      securities_basket: PropTypes.shape({
        basket: PropTypes.arrayOf(PropTypes.shape({})),
      }),
      applied_methodologies: PropTypes.arrayOf(PropTypes.shape({})),
      market_adjustment: PropTypes.number,
      adjust_for_market: PropTypes.bool,
      public_companies_status: PropTypes.arrayOf(PropTypes.shape({})),
      deleted_public_company_status_ids: PropTypes.arrayOf(PropTypes.number),
    }),
    allocationId: PropTypes.number,
  }),
  onChange: PropTypes.func,
  workbook: PropTypes.shape({}),
  approaches: PropTypes.arrayOf(PropTypes.shape({})),
  capTableList: PropTypes.arrayOf(PropTypes.shape({})),
};
