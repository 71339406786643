const reverseParser = ({ tableData, cells, initialObj }) => {
  const tmpFinancialsDate = { ...initialObj };
  Object.values(cells).forEach(({ alias, value }) => {
    if (alias) {
      tmpFinancialsDate[alias] = value;
    }
  });

  // eslint-disable-next-line no-param-reassign
  tableData.financial_statement = {
    ...tableData.financial_statement,
    financials_date: tmpFinancialsDate.financials_date,
  };

  return {
    ...initialObj,
    financials_date: tmpFinancialsDate.financials_date,
  };
};

export default reverseParser;
