import { isEmpty } from 'lodash';
import { FINANCIALS_REFERENCE_TYPE } from 'common/constants/documents';
import getFinancialsData from './getFinancialsData';
import updateOrCreateFinancialStatement from './updateOrCreateFinancialStatement';
/**
 *
 * @param {*} getFinancialsDataFn
 * @param {*} create
 * @param {*} getVersions
 * @param {*} update
 * @param {*} setFinancialStatementFn
 * @param {*} measurementDateParams
 * @param {*} filesParams
 * @param {*} notesParams
 */
const saveFinancialStatement = async (
  tableData,
  periods,
  financialStatement,
  create,
  getVersions,
  update,
  setFinancialStatementFn,
  measurementDateParams,
  filesParams,
  notesParams,
  showAdditionalHistoricalYears,
  useAdjustedEBITDA
) => {
  const { companyMeasurementDate, selectedMeasurementDate, measurementDates } = measurementDateParams;
  const { filesToSave, financialStatementId, addReferenceForExistingDocuments, setFilesToSave } = filesParams;
  const { notes, saveNotes, notesHasChanged } = notesParams;
  const { incomeStatement, balanceSheet, KPI, financialsDateSelectionSheet } = tableData;

  // Save files in widget
  if (!isEmpty(filesToSave)) {
    addReferenceForExistingDocuments(
      selectedMeasurementDate.id,
      filesToSave,
      financialStatementId,
      FINANCIALS_REFERENCE_TYPE
    );
    setFilesToSave([]);
  }
  // Save files in widget
  if (!isEmpty(notes) && notesHasChanged) {
    saveNotes();
  }
  // make sure kpi data is sent too
  const financialStatementData = getFinancialsData(incomeStatement, balanceSheet, KPI, financialStatement, periods);
  financialStatementData.company_measurement_date = companyMeasurementDate?.id;
  financialStatementData.measurement_date_id = selectedMeasurementDate?.id;
  financialStatementData.show_additional_historical_years = showAdditionalHistoricalYears;
  financialStatementData.use_adjusted_ebitda = useAdjustedEBITDA;
  financialStatementData.company_kpis = KPI.tableData.companyKPIs;
  financialStatementData.deleted_kpis = KPI.tableData.deletedKPIs || [];
  financialStatementData.financials_date = financialsDateSelectionSheet.tableData.financial_statement.financials_date;

  if (!financialStatementData.name) {
    const md = measurementDates.find(item => toString(item.id) === toString(selectedMeasurementDate?.id));

    const measurementDate = md.date;
    financialStatementData.name = `Financial Statement ${measurementDate}`;
  }

  try {
    await updateOrCreateFinancialStatement({
      financialStatementData,
      getVersions,
      create,
      update,
      financialStatementSetter: setFinancialStatementFn,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export default saveFinancialStatement;
