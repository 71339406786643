import { oneDecimalPercentFormat } from 'common/formats/formats';
import { RISK_FREE_RATE_CUSTOM_KEY } from 'pages/ValuationsAllocation/approaches/FutureExit/AllocationMethodTable/utils/constants';
import {
  ALLOCATION_SCENARIO_METHOD_OPM,
  ALLOCATION_SCENARIO_TYPE_BACKSOLVE,
  ALLOCATION_SCENARIO_TYPE_FUTURE_EXIT,
} from 'pages/ValuationsAllocation/common/constants/allocation';
import { getNumberValue } from 'utillities';
import { HandleRiskFreeRateParams } from './types';

const handleRiskFreeRate = (params: HandleRiskFreeRateParams) => {
  const { cell, isApproachWithOPM, scenarioMethod, scenarioType, relatedApproachOPMTableName } = params;

  cell.format = oneDecimalPercentFormat;
  cell.gridType = 'percentage';

  if (![ALLOCATION_SCENARIO_METHOD_OPM].includes(getNumberValue(scenarioMethod)) && !isApproachWithOPM) {
    cell.hidden = true;
  } else if (
    [ALLOCATION_SCENARIO_TYPE_BACKSOLVE, ALLOCATION_SCENARIO_TYPE_FUTURE_EXIT].includes(getNumberValue(scenarioType))
  ) {
    cell.expr = `=${relatedApproachOPMTableName}.${RISK_FREE_RATE_CUSTOM_KEY}`;
  }
};

export default handleRiskFreeRate;
