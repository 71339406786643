import React from 'react';
import { FINANCIALS_DATE_ALIAS, FINANCIALS_DATE_CUSTOM_KEY, FINANCIALS_DATE_TITLE } from 'common/constants/financials';
import { GridDate, GridDateEditor, GridDateValueViewer } from 'components/FeaturedSpreadsheet/components';
import { gridShortDate } from 'utillities';

const getRowConfig = ({ financialStatement, isDisabled }) => [
  {
    value: FINANCIALS_DATE_TITLE,
    alias: FINANCIALS_DATE_ALIAS,
    rowNumber: 1,
    customKey: FINANCIALS_DATE_CUSTOM_KEY,
    readOnly: isDisabled,
    dbType: 'string',
    defaultValue: gridShortDate(financialStatement?.financials_date),
    expr: '',
    gridDateComponent: GridDate,
    gridType: 'gridDate',
    dataEditor: props => <GridDateEditor {...props} />,
    valueViewer: props => <GridDateValueViewer {...props} />,
    ignoreRowCopy: true,
  },
];

export default getRowConfig;
