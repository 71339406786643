import { isNil } from 'lodash';
import { GREATER_THAN, IS_REQUIRED_ERROR } from 'common/constants/messages/validations';

const customValidations = ({ cell: tmpCell, addFeedback, removeFeedback }) => {
  const { value, isRequiredIfOPM, greaterThanIfOPM } = tmpCell;
  if (isRequiredIfOPM && (isNil(value) || value.toString().trim().length === 0)) {
    addFeedback(IS_REQUIRED_ERROR);
  } else {
    removeFeedback(IS_REQUIRED_ERROR);
  }

  // Greater than zero, could be 0.1 or 0.01...
  if (!isNil(greaterThanIfOPM) && Number(value) <= Number(greaterThanIfOPM)) {
    addFeedback(GREATER_THAN(greaterThanIfOPM));
  } else {
    removeFeedback(GREATER_THAN(greaterThanIfOPM));
  }
};

export default customValidations;
