import { EXTERNAL_VALUATION_REFERENCE_TYPE, SSV_REFERENCE_TYPE } from 'common/constants/documents';
import { VALUATIONS_OTHER_LABEL } from 'common/constants/valuations';
import {
  VALUATIONS_SPECIFIED_LABEL,
  VALUATIONS_SPREADSHEET_ENTERPRISE_VALUE_KEY,
} from 'pages/ValuationsAllocation/common/constants/valuations';

export const PUBLIC_COMPANIES = 'Public Comps';
export const PUBLIC_COMPANIES_LABEL = 'Public Comps';
export const PUBLIC_COMPANIES_MENU = 'Public Comps';
export const EXTERNAL_VALUATION = 'External Valuation';
export const DISCOUNT_CASH_FLOW = 'Discounted Cash Flow';
export const PUBLIC_TRANSACTIONS = 'Guideline Transactions';
export const PUBLIC_TRANSACTIONS_MENU = 'Transaction Comps';
export const PUBLIC_TRANSACTIONS_LABEL = 'Transaction Comps';
export const FUTURE_EXIT = 'Future Exit';
export const SPECIFIED_SHARE_VALUES = 'Specified Share Values';
export const SPECIFIED_SHARE_VALUES_MENU = 'Specified Share Values';
export const BACKSOLVE_APPROACH = 'Backsolve';
export const BACKSOLVE_APPROACH_MENU = 'Backsolve';
export const MA_COMPS = 'Transaction Comps';
export const GPC_COMPS = 'Public Comps';
export const CAP_TABLE_SELECTION = 'capTableSelection';

export const IMPLEMENTED_APPROACH_TYPES = [
  EXTERNAL_VALUATION,
  PUBLIC_COMPANIES,
  PUBLIC_TRANSACTIONS,
  DISCOUNT_CASH_FLOW,
  BACKSOLVE_APPROACH,
  SPECIFIED_SHARE_VALUES,
  FUTURE_EXIT,
];

export const CANNOT_DELETE_GPC = 'This approach cannot be deleted because it is being used in a Market Adjustment.';

export const CAP_TABLE_CURRENCY_APPROACHES = [BACKSOLVE_APPROACH, SPECIFIED_SHARE_VALUES];
export const APPROACH_GPC_PROPERTY = 'valuations_approach_gpc';
export const APPROACH_GPT_PROPERTY = 'valuations_approach_gpt';
export const APPROACH_DCF_PROPERTY = 'valuations_approach_dcf';
export const APPROACH_EV_PROPERTY = 'valuations_approach_ev';
export const APPROACH_SSV_PROPERTY = 'valuations_approach_ssv';
export const APPROACH_BACKSOLVE_PROPERTY = 'valuations_approach_backsolve';
export const FUTURE_EXIT_PROPERTY = 'valuations_approach_future_exit';

export const SUMMARY_LABEL = 'Summary';
export const APPROACHES_LABEL = 'Approaches';

export const AT_LEAST_TWO_COLUMNS = 'At least two columns are required to save this approach.';

export const FIRST_COL_TITLE = 'DCF Summary';
export const SECOND_COL_TITLE = 'Measurement Date';
export const WACC_TITLE = 'Weighted Average Cost Of Capital (WACC)';
export const SUMMED_PRESENT_VALUE_TITLE = 'Summed Present Value Of Discretes Period Cash Flows';
export const TERMINAL_VALUE_TITLE = 'Present Value Of Terminal Value';
export const ENTERPRISE_VALUE_TITLE = 'Enterprise Value';
export const PLUSH_CASH_TITLE = 'Plus: Cash';
export const NOL_TITLE = 'Present Value Of NOL Carryforwards';
export const MARKED_VALUE_TITLE = 'Marked Value Of Invested Capital (Rounded)';

export const WACC_ALIAS = 'wacc';
export const SUMMED_PRESENT_VALUE_ALIAS = 'summed_present_value';
export const TERMINAL_VALUE_ALIAS = 'present_value_of_terminal_value';
export const ENTERPRISE_VALUE_ALIAS = VALUATIONS_SPREADSHEET_ENTERPRISE_VALUE_KEY;
export const PLUSH_CASH_ALIAS = 'balance_sheet_ltm_cash';
export const NOL_ALIAS = 'nol_present_value_savings';
export const MARKED_VALUE_ALIAS = 'marked_value';

export const WACC_ROW_NUMBER = 2;
export const SUMMED_PRESENT_VALUE_ROW_NUMBER = 3;
export const TERMINAL_VALUE_ROW_NUMBER = 4;
export const NOL_ROW_NUMBER = 5;
export const ENTERPRISE_VALUE_ROW_NUMBER = 6;
export const TWENTY_FIVE_STRING = '25';
export const SEVENTY_FIVE_STRING = '75';

export const TERMINAL_VALUE_OPTIONS = {
  PERPETUITY_GROWTH: 'Perpetuity Growth',
  H_MODEL: 'H-Model',
  REVENUE_MULTIPLE: 'Revenue Multiple',
  EBITDA_MULTIPLE: 'EBITDA Multiple',
  REVENUE_AND_EBITDA_MULTIPLE: 'Rev & EBITDA Multiple',
};

// Multiple options constants
export const PERCENTILE_75 = '75th Percentile';
export const PERCENTILE_25 = '25th Percentile';
export const SPECIFIED_LABEL = VALUATIONS_SPECIFIED_LABEL;
export const MEDIAN_LABEL = 'Median';
export const MEAN_LABEL = 'Mean';
export const OTHER_LABEL = 'Other';
export const SPECIFIED_ID = null;

export const VALUES_OPTIONS = [MEDIAN_LABEL, MEAN_LABEL, PERCENTILE_75, PERCENTILE_25, SPECIFIED_LABEL];

export const MULTIPLE_OPTIONS = {
  MEAN: 'Mean',
  MEDIAN: 'Median',
  TWENTY_FIFTH: '25th',
  SEVENTY_FIFTH: '75th',
  OTHER: VALUATIONS_OTHER_LABEL,
};

export const TOTAL_CASH_FLOWS = 'total_cash_flows';
export const TERMINAL_VALUE = 'terminal_value';
export const WACC = 'wacc';
export const LONG_TERM_GROWTH_RATE = 'long_term_growth_rate';
export const DISCOUNT_FACTOR = 'discount_factor';
export const PV_OF_TERMINAL_VALUE = 'present_value_of_terminal_value';
export const YEARS_OF_HIGH_GROWTH = 'years_of_high_growth';
export const HIGH_GROWTH_RATE = 'high_growth_rate';
export const PERPETUITY_VALUE = 'perpetuity_value';
export const TERMINAL_YEAR_H_MODEL = 'terminal_year_h_model_value';
export const HALF_LIFE = 'half_life';
export const REVENUE_TOTAL = 'revenue_total';
export const EBITDA_TOTAL = 'ebitda_total';
export const METRIC_TOTAL = 'metric_total';
export const ESTIMATED_EXIT_VALUE = 'estimated_exit_value';
export const REVENUE_GPC_MULTIPLE_SELECTION = 'revenue_gpc_multiple_selection';
export const REVENUE_GPC_MULTIPLE = 'revenue_gpc_multiple';
export const EBITDA_GPC_MULTIPLE_SELECTION = 'ebitda_gpc_multiple_selection';
export const EBITDA_GPC_MULTIPLE = 'ebitda_gpc_multiple';
export const EBITDA_GPC_TITLE = 'ebitda_gpc_title';
export const GPC_MULTIPLE_SELECTION = 'gpc_multiple_selection';
export const GPC_MULTIPLE = 'gpc_multiple';
export const APPROACH_SELECTION = 'approach_selection';
export const REVENUE_APPROACH_SELECTION = 'revenue_approach_selection';
export const EBITDA_APPROACH_SELECTION = 'ebitda_approach_selection';
export const REVENUE_GPC_APPROACH_SELECTION = 'revenue_gpc_approach_selection';
export const REVENUE_GPT_APPROACH_SELECTION = 'revenue_gpt_approach_selection';
export const EBITDA_GPC_APPROACH_SELECTION = 'ebitda_gpc_approach_selection';
export const EBITDA_GPT_APPROACH_SELECTION = 'ebitda_gpt_approach_selection';

// DCF->TVT Table Row Number Constants
export const DCF_TVT_TERMINAL_YEAR_ROW_NUMBER = 2;
export const DCF_TVT_SELECTED_MULTIPLE_ROW_NUMBER = 3;
export const DCF_TVT_MULTIPLE_ROW_NUMBER = 4;
export const DCF_TVT_ESTIMATED_EXIT_ROW_NUMBER = 5;
export const DCF_TVT_WACC_ROW_NUMBER = 6;
export const DCF_TVT_DISCOUNT_PERIODS_ROW_NUMBER = 7;
export const DCF_TVT_PV_OF_TERMINAL_VALUE_ROW_NUMBER = 8;

export const DCF_TVT_COMBINED_TERMINAL_VALUE_ROW_NUMBER = 1;
export const DCF_TVT_COMBINED_TERMINAL_YEAR_ROW_NUMBER = 3;
export const DCF_TVT_COMBINED_SELECTED_APPROACH_ROW_NUMBER = 4;
export const DCF_TVT_COMBINED_SELECTED_MULTIPLE_ROW_NUMBER = 5;
export const DCF_TVT_COMBINED_MULTIPLE_ROW_NUMBER = 6;
export const DCF_TVT_COMBINED_ESTIMATED_EXIT_ROW_NUMBER = 7;
export const DCF_TVT_COMBINED_AVERAGE_EXIT_VALUE_ROW_NUMBER = 8;
export const DCF_TVT_COMBINED_WACC_ROW_NUMBER = 9;
export const DCF_TVT_COMBINED_DISCOUNT_PERIODS_ROW_NUMBER = 10;
export const DCF_TVT_COMBINED_PV_OF_TERMINAL_VALUE_ROW_NUMBER = 11;
export const VALUATIONS_MEDIAN_VALUE = 0;
export const VALUATIONS_OTHER_VALUE = 7;

export const WACC_LABEL = 'Weighted Average Cost of Capital (WACC)';

export const VALUATION_APPROACH_DCF_TABLE_NAME = 'valuations_approach_dcf';
export const DCF_FINANCIAL_PERIOD_TABLE_NAME = 'dcf_financial_period';

export const DISCOUNT_PERIODS = 'terminal_discount_periods';

export const REVENUE_TOTAL_TITLE = 'Total Revenues';
export const REVENUE_TOTAL_ALIAS = 'revenue_total';
export const REVENUE_TOTAL_ROW_NUMBER = 2;

export const REVENUE_GROWTH_TITLE = 'Revenue Growth Rate';
export const REVENUE_GROWTH_ALIAS = 'revenue_growth';
export const REVENUE_GROWTH_ROW_NUMBER = 3;

export const EBITDA_TOTAL_TITLE = 'EBITDA';
export const EBITDA_TOTAL_ALIAS = 'ebitda_total';
export const EBITDA_TOTAL_ROW_NUMBER = 4;

export const EBITDA_MARGIN_TITLE = 'EBITDA Margin';
export const EBITDA_MARGIN_ALIAS = 'ebitda_margin';
export const EBITDA_MARGIN_ROW_NUMBER = 5;

export const EBIT_TOTAL_TITLE = 'EBIT';
export const EBIT_TOTAL_ALIAS = 'ebit_total';
export const EBIT_TOTAL_ROW_NUMBER = 6;

export const EBIT_CASH_TAXES_TITLE = 'Less: Cash Taxes At Rate Of';
export const EBIT_CASH_TAXES_ALIAS = 'ebit_cash_taxes';
export const EBIT_CASH_TAXES_ROW_NUMBER = 7;

export const EBIT_DEPRECIATION_EXPENSES_TOTAL_TITLE = 'Depreciation Expense';
export const EBIT_DEPRECIATION_EXPENSES_TOTAL_ALIAS = 'ebit_depreciation_expenses_total';
export const EBIT_DEPRECIATION_EXPENSES_TOTAL_ROW_NUMBER = 8;

export const EBIT_DEPRECIATION_EXPENSES_PERCENT_TITLE = 'Depreciation As % Of Sales';
export const EBIT_DEPRECIATION_EXPENSES_PERCENT_ALIAS = 'ebit_depreciation_expenses_percent';
export const EBIT_DEPRECIATION_EXPENSES_PERCENT_ROW_NUMBER = 9;

export const EBIT_AMORTIZATION_EXPENSES_TOTAL_TITLE = 'Amortization Expense';
export const EBIT_AMORTIZATION_EXPENSES_TOTAL_ALIAS = 'ebit_amortization_expenses_total';
export const EBIT_AMORTIZATION_EXPENSES_TOTAL_ROW_NUMBER = 10;

export const EBIT_AMORTIZATION_EXPENSES_PERCENT_TITLE = 'Amortization As % Of Sales';
export const EBIT_AMORTIZATION_EXPENSES_PERCENT_ALIAS = 'ebit_amortization_expenses_percent';
export const EBIT_AMORTIZATION_EXPENSES_PERCENT_ROW_NUMBER = 11;

export const PLUS_DEPRECATION_AMORTIZATION_EXPENSE_TITLE = 'Plus: Depreciation & Amortization Expense';
export const PLUS_DEPRECATION_AMORTIZATION_EXPENSE_ALIAS = 'ebit_depreciation_amortization_total';
export const PLUS_DEPRECATION_AMORTIZATION_EXPENSE_ROW_NUMBER = 12;

export const EBIT_CAPITAL_EXPENSES_TOTAL_TITLE = 'Capital Expenditures';
export const EBIT_CAPITAL_EXPENSES_TOTAL_ALIAS = 'ebit_capital_expenses_total';
export const EBIT_CAPITAL_EXPENSES_TOTAL_ROW_NUMBER = 13;

export const EBIT_CAPITAL_EXPENSES_PERCENT_TITLE = 'Capital Expenditures As % Of Sales';
export const EBIT_CAPITAL_EXPENSES_PERCENT_ALIAS = 'ebit_capital_expenses_percent';
export const EBIT_CAPITAL_EXPENSES_PERCENT_ROW_NUMBER = 14;

export const EBIT_ADDITIONS_TO_INTANGIBLES_TOTAL_TITLE = 'Additions to Intangibles';
export const EBIT_ADDITIONS_TO_INTANGIBLES_TOTAL_ALIAS = 'ebit_additions_to_intangibles_total';
export const EBIT_ADDITIONS_TO_INTANGIBLES_TOTAL_ROW_NUMBER = 15;

export const EBIT_ADDITIONS_TO_INTANGIBLES_PERCENT_TITLE = 'Additions to Intangibles As % Of Sales';
export const EBIT_ADDITIONS_TO_INTANGIBLES_PERCENT_ALIAS = 'ebit_additions_to_intangibles_percent';
export const EBIT_ADDITIONS_TO_INTANGIBLES_PERCENT_ROW_NUMBER = 16;

export const LESS_CAPITAL_EXPENDITURES_ADDITIONS_TITLE = 'Less: Capital Expenditures & Additions To Intangibles';
export const LESS_CAPITAL_EXPENDITURES_ADDITIONS_ALIAS = 'ebit_capital_additions_total';
export const LESS_CAPITAL_EXPENDITURES_ADDITIONS_ROW_NUMBER = 17;

export const EBIT_CASH_FREE_NET_WORKING_CAPITAL_TOTAL_TITLE = 'Cash-Free Net Working Capital';
export const EBIT_CASH_FREE_NET_WORKING_CAPITAL_TOTAL_ALIAS = 'ebit_cash_free_net_working_capital_total';
export const EBIT_CASH_FREE_NET_WORKING_CAPITAL_TOTAL_ROW_NUMBER = 18;

export const EBIT_CASH_FREE_NET_WORKING_CAPITAL_PERCENT_TITLE = 'Cash-Free NWC as % Of Sales';
export const EBIT_CASH_FREE_NET_WORKING_CAPITAL_PERCENT_ALIAS = 'ebit_cash_free_net_working_capital_percent';
export const EBIT_CASH_FREE_NET_WORKING_CAPITAL_PERCENT_ROW_NUMBER = 19;

export const INCREASE_DECREASE_CASH_FREE_NET_WORKING_CAPITAL_TITLE
  = '(Increase) / Decrease In Cash-Free Net Working Capital';
export const INCREASE_DECREASE_CASH_FREE_NET_WORKING_CAPITAL_ALIAS = 'ebit_increase_cash_free_net_working_capital';
export const INCREASE_DECREASE_CASH_FREE_NET_WORKING_CAPITAL_ROW_NUMBER = 20;

export const NET_CASH_FLOWS_TITLE = 'Net Cash Flows To Invested Capital';
export const NET_CASH_FLOWS_ALIAS = 'net_cash_flows';
export const NET_CASH_FLOWS_ROW_NUMBER = 21;

export const CASH_FLOW_GROWTH_TITLE = 'Cash Flow Growth Rate';
export const CASH_FLOW_GROWTH_ALIAS = 'cash_flow_growth';
export const CASH_FLOW_GROWTH_ROW_NUMBER = 22;

export const CASH_FLOW_REMAINING_TITLE = 'Cash Flows Remaining Through End Of Year';
export const CASH_FLOW_REMAINING_ALIAS = 'cash_flow_remaining';
export const CASH_FLOW_REMAINING_ROW_NUMBER = 23;

export const DISCOUNT_PERIODS_TITLE = 'Discount Periods (Mid-Year Convention)';
export const DISCOUNT_PERIODS_ALIAS = 'discount_periods';
export const DISCOUNT_PERIODS_ROW_NUMBER = 24;

export const DISCOUNT_FACTOR_TITLE = 'Discount Factor - Based On WACC Of';
export const DISCOUNT_FACTOR_ALIAS = 'discount_factor';
export const DISCOUNT_FACTOR_ROW_NUMBER = 25;

export const TOTAL_CASH_FLOWS_TITLE = 'Net Cash Flows To Invested Capital';
export const TOTAL_CASH_FLOWS_ALIAS = 'total_cash_flows';
export const TOTAL_CASH_FLOWS_ROW_NUMBER = 26;

export const MAX_VALUATION_NUMBER_DIGITS = 15;

export const SELECT_MULTIPLE_ALIASES = [
  REVENUE_GPC_MULTIPLE_SELECTION,
  EBITDA_GPC_MULTIPLE_SELECTION,
  GPC_MULTIPLE_SELECTION,
];
export const APPROACH_SELECTIONS_ALIASES = [APPROACH_SELECTION, REVENUE_APPROACH_SELECTION, EBITDA_APPROACH_SELECTION];
export const MULTIPLE_ALIASES = [REVENUE_GPC_MULTIPLE, EBITDA_GPC_MULTIPLE, GPC_MULTIPLE];

export const REM_TVT_SECOND_COLUMN = 'B';
export const GPT_EBITDA_COLUMN_LEGEND = 'G';
export const GPT_REVENUE_COLUMN_LEGEND = 'F';
export const GPC_LTM_REVENUE_COLUMN_LEGEND = 'E';
export const GPC_LTM_EBITDA_COLUMN_LEGEND = 'F';

export const ROW_WITH_PAIRS = [
  REVENUE_TOTAL_ALIAS,
  REVENUE_GROWTH_ALIAS,
  EBIT_DEPRECIATION_EXPENSES_TOTAL_ALIAS,
  EBIT_DEPRECIATION_EXPENSES_PERCENT_ALIAS,
  EBIT_AMORTIZATION_EXPENSES_TOTAL_ALIAS,
  EBIT_AMORTIZATION_EXPENSES_PERCENT_ALIAS,
  EBIT_CAPITAL_EXPENSES_TOTAL_ALIAS,
  EBIT_CAPITAL_EXPENSES_PERCENT_ALIAS,
  EBIT_ADDITIONS_TO_INTANGIBLES_TOTAL_ALIAS,
  EBIT_ADDITIONS_TO_INTANGIBLES_PERCENT_ALIAS,
  EBIT_CASH_FREE_NET_WORKING_CAPITAL_TOTAL_ALIAS,
  EBIT_CASH_FREE_NET_WORKING_CAPITAL_PERCENT_ALIAS,
];
export const BENCHMARK_APPROACH_TYPES = [PUBLIC_TRANSACTIONS, PUBLIC_COMPANIES];
export const UPDATED_BENCHMARK_ROWS = 'updated_benchmark_rows';

export const EXTERNAL_VALUE = 'External Valuation';
export const PRIVATE_TRANSACTIONS = 'Private Transactions';

export const VALUATION = 'valuation';

export const SCALAR_COMP_GROUPS = 'SCALAR COMP GROUPS';
export const FIRM_COMP_GROUPS = 'FIRM COMP GROUPS';
export const WAS_NOT_FOUND_MD = 'was not found for the current measurement date.';
export const USE_CAP_IQ_ID
  = 'A Company ID from Capital IQ can be used instead of the stock ticker to retrieve data on acquired companies or other entities that are not available via the ticker. The Capital IQ Company ID uses the format ';

export const valuationReferencesMap = new Map([
  [EXTERNAL_VALUE, EXTERNAL_VALUATION_REFERENCE_TYPE],
  [SPECIFIED_SHARE_VALUES, SSV_REFERENCE_TYPE],
]);

export const REVERT_APPROACH_SELECTION = {
  [REVENUE_GPC_APPROACH_SELECTION]: REVENUE_GPT_APPROACH_SELECTION,
  [REVENUE_GPT_APPROACH_SELECTION]: REVENUE_GPC_APPROACH_SELECTION,
  [EBITDA_GPC_APPROACH_SELECTION]: EBITDA_GPT_APPROACH_SELECTION,
  [EBITDA_GPT_APPROACH_SELECTION]: EBITDA_GPC_APPROACH_SELECTION,
};

export const LINK_WITH_GPC = 'link_with_gpc';
export const VALUATION_APPROACH_GPC = 'valuation_approach_gpc';
export const LINK_WITH_GPT = 'link_with_gpt';
export const VALUATION_APPROACH_GPT = 'valuation_approach_gpt';

export const SHOW_FULL_DESCRIPTION = 'SHOW FULL DESCRIPTION';
export const SHOW_LESS_DESCRIPTION = 'SHOW LESS';

export const MISSING_FINANCIAL_STATEMENT = 'There is no financial statement for this measurement date';
export const SAVE_FINANCIALS_TO_CONTINUE = 'Please save a financial statement to continue';

export const SORT_LABEL = 'SORT';
export const SORT_ASC = 'asc';
export const SORT_DESC = 'desc';
