import React, { FC, useContext } from 'react';
import { GridSelect } from 'components/FeaturedSpreadsheet/components';
import ValuationContext from '../../ValuationContext';

const AllocationMethodSelector: FC = props => {
  const { allocationMethodsOptions } = useContext(ValuationContext);

  const selectProps = {
    menuPosition: 'fixed',
    objectOptions: { displayKey: 'name', valueKey: 'id' },
    options: allocationMethodsOptions,
  };

  return <GridSelect {...props} {...selectProps} />;
};

export default AllocationMethodSelector;
