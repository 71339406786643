import { createContext } from 'react';

const ExcelExportContext = createContext({
  doReportPollCheck: false,
  reportRequestData: {},
  reportUUID: null,
  setDoReportPollCheck: () => {},
  setReportRequestData: () => {},
  setReportUUID: () => {},
  fundsByMeasurementDate: [],
  setFundsByMeasurementDate: () => {},
});

ExcelExportContext.displayName = 'ExcelExportContext';

export default ExcelExportContext;
